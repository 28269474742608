import useSWR from 'swr';
import { useMemo } from 'react';

import { fetcher, endpoints } from 'src/utils/axios';
import { getDomainName } from 'src/utils/client-common';

import { API } from 'src/helpers/api';

import { ActionType, ActionTypes } from '../contexts/AppContext';

export const getActivityByDomain = async (dispatch: React.Dispatch<ActionType>) => {
  try {
    const { data } = await API({
      url: `/smbActivity/getActivityByDomain?domain=${getDomainName()}`,
      method: 'GET',
    });

    if (data && data.payload) {
      dispatch({
        type: ActionTypes.SET_SMB_ACCOUNT,
        payload: {
          products: data.payload,
          smbAccount: data.payload.account,
          smbActivityId: data.payload.id,
        },
      });
    }
  } catch (e) {
    console.error(e);
  }
};

export function useGetActivityByDomain() {
  const domain = getDomainName();

  const URL = [endpoints.activity.getActivity, { params: { domain } }];

  const { data } = useSWR(URL, fetcher, {
    keepPreviousData: true,
  });

  const memoizedValue = useMemo(
    () => ({
      activity: data?.payload,
    }),
    [data?.payload]
  );

  return memoizedValue;
}
export async function fetchActivitiesByMerchantId(merchantId: number) {
  try {
    const { data } = await API({
      url: `/smbActivity/getActivitiesByMerchantId`,
      method: 'GET',
      params: {
        merchantId,
      },
    });
    console.log('fetchActivitiesByMerchantId', data);
    return data;
  } catch (e) {
    console.error(e);
    throw e;
  }
}
