'use client';

import { useMemo, useState, useEffect } from 'react';

import axiosInstance, { endpoints } from 'src/utils/axios';

import { useGetActivityByDomain } from 'src/api/activities';

import { IOSection, BlockTypes, SectionTypes } from 'src/types/generator';

import { ContentContext } from './content-context';

// ----------------------------------------------------------------------

type ContentProviderProps = {
  children: React.ReactNode;
};

export function ContentProvider({ children }: ContentProviderProps) {
  const { activity } = useGetActivityByDomain();

  const [sections, setSections] = useState<IOSection[]>([]);
  const allContentAuth =
    (sections
      ?.find((section) => section.sectionType === SectionTypes.generalSettings)
      ?.data.main.find((block) => block.type === BlockTypes.accessRestrictions)
      ?.allContentAuth as boolean) || false;

  const openSite =
    (sections
      ?.find((section) => section.sectionType === SectionTypes.generalSettings)
      ?.data.main.find((block) => block.type === BlockTypes.accessRestrictions)
      ?.openSite as boolean) || false;

  const authVariantInActivity =
    (sections
      ?.find((section) => section.sectionType === SectionTypes.generalSettings)
      ?.data.main.find((block) => block.type === BlockTypes.accessRestrictions)
      ?.inActivity as boolean) || false;

  const authVariantInMerchant =
    (sections
      ?.find((section) => section.sectionType === SectionTypes.generalSettings)
      ?.data.main.find((block) => block.type === BlockTypes.accessRestrictions)
      ?.inMerchant as boolean) || false;

  const authVariantAll =
    (sections
      ?.find((section) => section.sectionType === SectionTypes.generalSettings)
      ?.data.main.find((block) => block.type === BlockTypes.accessRestrictions)
      ?.all as boolean) || false;

  const merchantLogo =
    (sections
      ?.find((section) => section.sectionType === SectionTypes.generalSettings)
      ?.data.main.find((block) => block.type === BlockTypes.accessRestrictions)
      ?.merchantLogo as boolean) || false;

  const globalSite =
    (sections
      ?.find((section) => section.sectionType === SectionTypes.generalSettings)
      ?.data.main.find((block) => block.type === BlockTypes.accessRestrictions)
      ?.globalSite as boolean) || false;

  useEffect(() => {
    const activityId = activity?.id;
    if (activityId) {
      getSections();
    }
    async function getSections() {
      try {
        const response = await axiosInstance.get(endpoints.generator.getSections, {
          params: { activityId },
        });
        setSections(response.data);
      } catch (err) {
        console.error(err);
      }
    }
  }, [activity]);

  const memoizedValue = useMemo(
    () => ({
      sections,
      allContentAuth,
      openSite,
      authVariantInActivity,
      authVariantInMerchant,
      authVariantAll,
      merchantLogo,
      globalSite
    }),
    [sections]
  );

  return <ContentContext.Provider value={memoizedValue}>{children}</ContentContext.Provider>;
}
